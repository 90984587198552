import { randomUUID } from 'crypto'

import type { NextRequest } from 'next/server'

export const ANALYTICS_IDENTIFIER_KEY = '__analytics__'

/**
 * This function will obtain the unique identifier that is used to identify anonymous users.
 */
export const getAnalyticsIdentifier = (req: NextRequest) => {
  let identifier: string

  if (req.cookies.get(ANALYTICS_IDENTIFIER_KEY)) {
    identifier = req.cookies.get(ANALYTICS_IDENTIFIER_KEY)?.value as string
  } else {
    identifier = randomUUID()
  }

  return identifier
}

/**
 * Helper method that checks if a user belongs to the circle domain and is therefore
 * an internal user.
 */
export const isCircleUser = (email: string) => {
  return email.endsWith('@circle.com')
}
