'use client'
import { ANALYTICS_IDENTIFIER_KEY } from '@shared/analytics/utils'
import { uuid } from '@shared/utils'
import { createActorContext } from '@xstate/react'
import Cookies from 'js-cookie'
import { createMachine, assign } from 'xstate'

import type { Question } from './types'

export type Result =
  | 'usdc-access'
  | 'on-off-ramps'
  | 'otc'
  | 'circle-mint'
  | 'crypto-service-providers'

type EmailSource = 'url' | 'form' | ''

const anonymousId = Cookies.get(ANALYTICS_IDENTIFIER_KEY) || uuid()

/**
 * Wizard context. Represents current state of the application.
 */
interface WizardContext {
  /**
   * Unique session identifier to keep track of user journey. UUID string created when page is loaded.
   */
  identifier: string
  /**
   * All available questions.
   */
  questions: Question[]
  /**
   * Currently active question. Matches to the last questionId in the stepStack.
   */
  currentQuestion: Question | null
  /**
   * Array of questionIds of answered questions.
   */
  stepStack: string[]
  /**
   * Array of chosen answer titles.
   */
  answerStack: string[]
  /**
   * User email.
   */
  email: string
  /**
   * Source of the user email.
   */
  emailSource: EmailSource
  /**
   * Represents result variant that will be shown to the user.
   */
  result: Result | null
  /**
   * Indicates that wizard was completed and result recorded.
   */
  completed: boolean
  /**
   * Indicates that wizard is doing something.
   */
  loading: boolean
  /**
   * Indicates that there was an error in recording completion.
   */
  error: boolean
}

export type WizardEvent =
  | { type: 'SET_QUESTIONS'; questions: Question[] }
  | { type: 'SET_NEXT_QUESTION_ID'; questionId: string; answer: string }
  | { type: 'BACK' }
  | { type: 'SET_EMAIL'; email: string; source?: EmailSource }
  | { type: 'SET_RESULT'; result: Result; answer: string }
  | { type: 'START_OVER' }
  | { type: 'COMPLETED' }
  | { type: 'ERROR' }
  | { type: 'LOADING'; loading: boolean }

export const initialState: WizardContext = {
  identifier: 'uuid',
  questions: [],
  currentQuestion: null,
  stepStack: [],
  answerStack: [],
  email: '',
  emailSource: 'form',
  result: null,
  error: false,
  completed: false,
  loading: false,
}

export const wizardMachine = createMachine({
  types: {} as {
    context: WizardContext
    events: WizardEvent
  },
  id: 'wizard',
  initial: 'questions',
  context: { ...initialState, identifier: anonymousId },
  states: {
    questions: {
      on: {
        SET_QUESTIONS: {
          actions: assign({
            questions: ({ event }) => event.questions,
            currentQuestion: ({ event }) =>
              event.questions.length > 0 ? event.questions[0] : null,
            stepStack: ({ event }) =>
              event.questions.length > 0 ? [event.questions[0].id] : [],
            answerStack: [],
          }),
        },
        SET_NEXT_QUESTION_ID: {
          actions: assign({
            currentQuestion: ({
              context: { questions },
              event: { questionId },
            }) => {
              return (
                questions.find((question) => questionId === question.id) || null
              )
            },
            stepStack: ({ context: { stepStack }, event: { questionId } }) => {
              return [...stepStack, questionId]
            },
            answerStack: ({ context: { answerStack }, event: { answer } }) => {
              return [...answerStack, answer]
            },
          }),
        },
        BACK: {
          actions: assign({
            stepStack: ({ context }) => context.stepStack.slice(0, -1),
            answerStack: ({ context }) => context.answerStack.slice(0, -1),
            currentQuestion: ({ context: { stepStack, questions } }) => {
              const current = stepStack[stepStack.length - 2]
              return (
                questions.find((question) => question.id === current) || null
              )
            },
          }),
        },
        SET_RESULT: {
          actions: assign({
            result: ({ event }) => event.result,
            stepStack: ({ context: { stepStack }, event: { result } }) => {
              return [...stepStack, result]
            },
            answerStack: ({ context: { answerStack }, event: { answer } }) => {
              return [...answerStack, answer]
            },
          }),
          target: 'results',
        },
      },
    },
    results: {
      on: {
        BACK: {
          actions: assign({
            stepStack: ({ context }) => context.stepStack.slice(0, -1),
            answerStack: ({ context }) => context.answerStack.slice(0, -1),
            result: null,
            completed: false,
            error: false,
          }),
          target: 'questions',
        },
        COMPLETED: {
          actions: assign({
            completed: true,
            error: false,
          }),
        },
        ERROR: {
          actions: assign({
            completed: true,
            error: true,
          }),
        },
      },
    },
  },
  on: {
    START_OVER: {
      actions: assign({
        currentQuestion: ({ context: { questions } }) => questions[0],
        stepStack: ({ context: { questions } }) => [questions[0].id],
        email: ({ context: { email, emailSource } }) =>
          emailSource === 'url' ? email : '',
        answerStack: [],
        result: null,
        completed: false,
        error: false,
      }),
      target: '.questions',
    },
    SET_EMAIL: {
      actions: assign({
        email: ({ event }) => event.email,
        emailSource: ({ event, context }) =>
          event.source ? event.source : context.emailSource,
      }),
    },
    LOADING: {
      actions: assign({
        loading: ({ event }) => event.loading,
      }),
    },
  },
})

const WizardContext = createActorContext(wizardMachine)
const WizardProvider = WizardContext.Provider

export { WizardContext, WizardProvider }
