'use client'

import '@circlefin/recaptcha/css/styles.css'
import { ReCaptchaProvider } from '@circlefin/recaptcha/react'

export interface GoogleReCaptchaProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Configured ReCaptcha site key.
   */
  siteKey: string
}

export const GoogleReCaptchaProvider: React.FC<
  GoogleReCaptchaProviderProps
> = ({ children, siteKey }) => {
  return <ReCaptchaProvider siteKey={siteKey}>{children}</ReCaptchaProvider>
}
